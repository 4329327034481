import Permission from '@/models/permission';
import IPermissionRepository from '@/repositories/IPermissionRepository';
import { $inject } from '@vanroeybe/vue-inversify-plugin';
import { inject, injectable } from 'inversify';
import IUseCase from '../IUseCase';

@injectable()
export default class GetAvailablePermissions implements IUseCase<(permissions: Permission[]) => void, () => void> {

    @$inject()
    private readonly permissionRepository!: IPermissionRepository

    call(parameter: (permissions: Permission[]) => void): () => void {
        return this.permissionRepository.getAvailablePermissions(parameter)
    }
}