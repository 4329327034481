import User from '@/models/user';
import IUserRepository from '@/repositories/IUserRepository';
import { $inject } from '@vanroeybe/vue-inversify-plugin';
import { injectable } from 'inversify';
import IUseCase from '../IUseCase';

@injectable()
export default class GetUser implements IUseCase<string, Promise<User | undefined>> {

    @$inject()
    private readonly userRepository!: IUserRepository

    call(parameter: string): Promise<User | undefined> {
        return this.userRepository.getUser(parameter)
    }
}