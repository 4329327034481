import firebase from 'firebase/app'
import 'firebase/firestore'

import permission, { permissionConverter } from '@/models/permission';
import IPermissionRepository from '../IPermissionRepository';
import { injectable } from 'inversify';

@injectable()
export default class FirebasePermissionRepository implements IPermissionRepository {
    getAvailablePermissions(observer: (permissions: permission[]) => void): () => void {
        return firebase.firestore().collection('permissions').withConverter(permissionConverter).onSnapshot(snap => {
            observer(snap.docs.map(doc => doc.data()))
        })
    }

}