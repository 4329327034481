


































import { Component, Vue } from 'vue-property-decorator'
import LoginViewModel from './loginViewModel'

@Component
export default class Login extends Vue {

  viewModel = new LoginViewModel()
}

