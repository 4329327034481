import tag, { tagConverter } from '@/models/tag';
import firebase from 'firebase/app'
import 'firebase/firestore'
import { injectable } from 'inversify';

import ITagRepository from '../ITagRepository';

@injectable()
export default class FirebaseTagRepository implements ITagRepository {
    getAllTags(observer: (tags: tag[]) => void): () => void {
        return firebase.firestore().collection('/tags').withConverter(tagConverter).onSnapshot(snap => {
            const tags = snap.docs.map(doc => doc.data())
            observer(tags)
        })
    }

    async createTagsIfNeeded(tags: string[] | undefined): Promise<void> {
        if (tags == undefined || tags == null || tags.length == 0) {
            return
        }

        const batch = firebase.firestore().batch()

        tags.forEach(tag => {
            const ref = firebase.firestore().doc('tags/' + tag)
            batch.set(ref, { 'name': tag })
        })

        return batch.commit()
    }
}