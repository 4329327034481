import 'reflect-metadata'

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Vuetify from 'vuetify'
import vuetify from './plugins/vuetify'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import { vueInversifyPlugin } from '@vanroeybe/vue-inversify-plugin'
import { container } from './utils/AmsContainer'

const firebaseConfig = {
  apiKey: 'AIzaSyB64H8gIKEgkXmvBXjosk7VshAjqdjMRZc',
  authDomain: 'infectiongames-ams.firebaseapp.com',
  databaseURL: 'https://infectiongames-ams.firebaseio.com',
  projectId: 'infectiongames-ams',
  storageBucket: 'infectiongames-ams.appspot.com',
  messagingSenderId: '619779831763',
  appId: '1:619779831763:web:0130727bcbe37732506126'
}

firebase.initializeApp(firebaseConfig)

Vue.config.productionTip = false

Vue.use(vueInversifyPlugin(container))

Vue.use(Vuetify)

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')

