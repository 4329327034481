import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/storage'

import asset, { assetConverter } from '@/models/asset';
import IAssetRepository from '../IAssetRepository';
import Asset from '@/models/asset';
import { injectable } from 'inversify';

@injectable()
export default class FirebaseAssetRepository implements IAssetRepository {
    getAssets(observer: (assets: asset[]) => void): () => void {
        return firebase.firestore().collection('assets').withConverter(assetConverter).onSnapshot(snapshot => {
            const assets = snapshot.docs.map(doc => doc.data())
            observer(assets)
        })
    }

    updateAsset(asset: asset): Promise<void> {
        return firebase.firestore().doc('assets/' + asset.name)
            .withConverter(assetConverter)
            .set(asset)
    }

    async uploadAsset(asset: asset, file: File, onProgress: (progress: number) => void): Promise<string> {
        const filePath = this.getFilePath(asset)
        const assetName = this.getAssetName(asset)
        const fileExtension = file.name.substring(file.name.lastIndexOf('.') + 1).toLowerCase()

        if (asset.assetUrl != null) {
            await firebase.storage().refFromURL(asset.assetUrl).delete()
        }

        const uploadTask = firebase.storage().ref(filePath + '/' + assetName + '.' + fileExtension).put(file)
        uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, async snapshot => {
            const progress = snapshot.bytesTransferred / snapshot.totalBytes * 100
            onProgress(progress)
        })

        const snapshot = await uploadTask
        if (snapshot.state == firebase.storage.TaskState.SUCCESS) {
            return snapshot.ref.getDownloadURL()
        } else {
            return ''
        }
    }

    async uploadThumbnail(asset: asset, file: File, onProgress: (progress: number) => void): Promise<string> {
        const filePath = this.getFilePath(asset)
        const uploadTask = firebase.storage().ref(filePath + '/thumbnail.png').put(file)

        uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED,
            async snapshot => {
                const progress = snapshot.bytesTransferred / snapshot.totalBytes * 100
                onProgress(progress)
            })

        const snapshot = await uploadTask
        if (snapshot.state == firebase.storage.TaskState.SUCCESS) {
            return snapshot.ref.getDownloadURL()
        } else {
            return ''
        }
    }

    async getAsset(assetname: string): Promise<Asset> {
        const doc = await firebase.firestore().doc('assets/' + assetname).withConverter(assetConverter).get()
        const data = doc.data()
        if (doc.exists && data) {
            return data
        } else {
            throw new Error('asset not found')
        }
    }

    async deleteAsset(asset: Asset): Promise<void> {
        const path = this.getFilePath(asset)
        if (asset.thumbnailUrl) {
            await firebase.storage().refFromURL(asset.thumbnailUrl).delete()
        }

        if (asset.assetUrl) {
            await firebase.storage().refFromURL(asset.assetUrl).delete()
        }

        return firebase.firestore().doc('assets/' + asset.name).delete()
    }

    private getAssetName(asset: Asset) {
        return asset.name?.substring(asset.name.lastIndexOf('.') + 1)
    }

    private getFilePath(asset: Asset) {
        return asset.name?.replaceAll('.', '/')
    }
}