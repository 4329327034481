










































import { Component, InjectReactive, Prop } from 'vue-property-decorator';
import Vue from 'vue'
import Asset, { AssetType } from '@/models/asset';
import AssetsViewModel from './assetsViewModel';

@Component
export default class AssetItem extends Vue {

  @InjectReactive() viewModel!: AssetsViewModel
  @Prop() asset!: Asset

  showModel = true

  get has3DModel() {
    return this.asset.assetType == AssetType.Fbx
  }

  download() {
    const link = document.createElement('a')
    if (this.asset.assetUrl) {
      link.href = this.asset.assetUrl
      link.setAttribute('download', 'file.png')
      document.body.appendChild(link)
      link.click()
    }

  }
}
