import Permission from '@/models/permission';
import User from '@/models/user';
import Logout from '@/useCases/auth/Logout';
import ObserverAuthChanged from '@/useCases/auth/ObserverAuthChanged';
import GetPermissions, { GetPermissionsParams } from '@/useCases/user/GetPermissions';
import { TYPES } from '@/utils/AmsContainer';
import { $inject } from '@vanroeybe/vue-inversify-plugin';

export default class AuthService {

    @$inject(TYPES.ObserverAuthChanged)
    private readonly observeAuthChanged!: ObserverAuthChanged

    @$inject(TYPES.Logout)
    private readonly logoutUseCase!: Logout

    @$inject(TYPES.GetPermissions)
    private readonly getPermissions!: GetPermissions

    private authChangedSubscription: () => void
    private permissionSubscription?: () => void

    public loggedInUser: User | null = null
    public loggedInPermissions: Permission[] = []

    showEditProfile = false

    constructor() {
        this.authChangedSubscription = this.observeAuthChanged.call(user => {
            this.loggedInUser = user

            this.showEditProfile = user != null && user?.username == null
            if (user) {
                if (this.permissionSubscription) {
                    this.permissionSubscription()
                }

                this.permissionSubscription = this.getPermissions.call(new GetPermissionsParams(user.id!, permissions => {
                    this.loggedInPermissions = permissions
                }))
            }
        })
    }

    public dispose() {
        if (this.authChangedSubscription) {
            this.authChangedSubscription()
        }

        if (this.permissionSubscription) {
            this.permissionSubscription()
        }
    }

    public logout(): void {
        this.logoutUseCase.call()
    }
}