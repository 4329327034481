import Status from '@/models/status';
import StatusRepository from '@/repositories/firebase/FirebaseStatusRepository';
import IStatusRepository from '@/repositories/IStatusRepository';
import { $inject } from '@vanroeybe/vue-inversify-plugin';
import { injectable } from 'inversify';
import { provide } from 'inversify-binding-decorators';
import IUseCase from '../IUseCase';
import GetStatusList from './GetStatusList';


export default class CreateOrUpdateStatus implements IUseCase<Status, Promise<void>> {

    @$inject()
    private readonly statusRepository!: IStatusRepository

    call(parameter: Status): Promise<void> {
        return this.statusRepository.createOrUpdateStatus(parameter)
    }
}