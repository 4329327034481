import Asset from '@/models/asset';
import IAssetRepository from '@/repositories/IAssetRepository';
import { $inject } from '@vanroeybe/vue-inversify-plugin';
import { injectable } from 'inversify';
import IUseCase from '../IUseCase';

@injectable()
export default class DeleteAsset implements IUseCase<Asset, Promise<void>> {

    @$inject()
    private readonly assetRepository!: IAssetRepository

    call(parameter: Asset): Promise<void> {
        return this.assetRepository.deleteAsset(parameter)
    }

}