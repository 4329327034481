import FirebaseAssetRepository from '@/repositories/firebase/FirebaseAssetRepository';
import FirebaseAuthRepository from '@/repositories/firebase/FirebaseAuthRepository';
import FirebasePermissionRepository from '@/repositories/firebase/FirebasePermissionRepository';
import FirebaseStatusRepository from '@/repositories/firebase/FirebaseStatusRepository';
import FirebaseTagRepository from '@/repositories/firebase/FirebaseTagRepository';
import FirebaseUserRepository from '@/repositories/firebase/FirebaseUserRepository';
import IAssetRepository from '@/repositories/IAssetRepository';
import IAuthRepository from '@/repositories/IAuthRepository';
import IPermissionRepository from '@/repositories/IPermissionRepository';
import IStatusRepository from '@/repositories/IStatusRepository';
import ITagRepository from '@/repositories/ITagRepository';
import IUserRepository from '@/repositories/IUserRepository';
import DeleteAsset from '@/useCases/asset/DeleteAsset';
import GetAsset from '@/useCases/asset/GetAsset';
import GetAssets from '@/useCases/asset/GetAssets';
import UpdateAsset from '@/useCases/asset/UpdateAsset';
import Login from '@/useCases/auth/Login';
import Logout from '@/useCases/auth/Logout';
import ObserverAuthChanged from '@/useCases/auth/ObserverAuthChanged';
import GetAvailablePermissions from '@/useCases/permissions/GetAvailablePermissions';
import UpdateProfile from '@/useCases/profile/UpdateProfile';
import GetStatus from '@/useCases/status/GetStatus';
import GetStatusList from '@/useCases/status/GetStatusList';
import GetTags from '@/useCases/tags/GetTags';
import GetPermissions from '@/useCases/user/GetPermissions';
import GetUser from '@/useCases/user/GetUser';
import GetUserList from '@/useCases/user/GetUserList';
import UpdatePermissions from '@/useCases/user/UpdatePermissions';
import { Container } from 'inversify';

const TYPES = {
    IStatusRepository: 'IStatusRepository',
    IUserRepository: 'IUserRepository',
    IAuthRepository: 'IAuthRepository',
    ITagRepository: 'ITagRepository',
    IAssetRepository: 'IAssetRepository',
    IPermissionRepository: 'IPermissionRepository',
    GetStatus: 'GetStatus',
    GetUserList: 'GetUserList',
    GetUser: 'GetUser',
    UpdateProfile: 'UpdateProfile',
    Logout: 'Logout',
    UpdateAsset: 'UpdateAsset',
    GetAsset: 'GetAsset',
    GetAssets: 'GetAssets',
    DeleteAsset: 'DeleteAsset',
    GetTags: 'GetTags',
    GetAvailablePermission: 'GetAvailablePermission',
    GetPermissions: 'GetPermissions',
    UpdatePermissions: 'UpdatePermissions',
    Login: 'Login',
    ObserverAuthChanged: 'ObservedAuthChanged'
}

const container = new Container()

// Repositories
container.bind<IStatusRepository>(TYPES.IStatusRepository).to(FirebaseStatusRepository)
container.bind<IUserRepository>(TYPES.IUserRepository).to(FirebaseUserRepository)
container.bind<IAuthRepository>(TYPES.IAuthRepository).to(FirebaseAuthRepository)
container.bind<ITagRepository>(TYPES.ITagRepository).to(FirebaseTagRepository)
container.bind<IAssetRepository>(TYPES.IAssetRepository).to(FirebaseAssetRepository)
container.bind<IPermissionRepository>(TYPES.IPermissionRepository).to(FirebasePermissionRepository)

//UseCases
container.bind<GetStatus>(TYPES.GetStatus).to(GetStatus)
container.bind<GetUserList>(TYPES.GetUserList).to(GetUserList)
container.bind<GetUser>(TYPES.GetUser).to(GetUser)
container.bind<UpdateProfile>(TYPES.UpdateProfile).to(UpdateProfile)
container.bind<Logout>(TYPES.Logout).to(Logout)
container.bind<UpdateAsset>(TYPES.UpdateAsset).to(UpdateAsset)
container.bind<GetAsset>(TYPES.GetAsset).to(GetAsset)
container.bind<GetAssets>(TYPES.GetAssets).to(GetAssets)
container.bind<DeleteAsset>(TYPES.DeleteAsset).to(DeleteAsset)
container.bind<GetTags>(TYPES.GetTags).to(GetTags)
container.bind<GetAvailablePermissions>(TYPES.GetAvailablePermission).to(GetAvailablePermissions)
container.bind<GetPermissions>(TYPES.GetPermissions).to(GetPermissions)
container.bind<UpdatePermissions>(TYPES.UpdatePermissions).to(UpdatePermissions)
container.bind<Login>(TYPES.Login).to(Login)
container.bind<ObserverAuthChanged>(TYPES.ObserverAuthChanged).to(ObserverAuthChanged)

export { container, TYPES }
