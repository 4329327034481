






































import Vue from 'vue'
import Component from 'vue-class-component';
import NumberPicker from '@/components/NumberPicker.vue'
import { InjectReactive } from 'vue-property-decorator';
import AuthService from '@/services/AuthService';
import EditStatusViewModel from './editStatusViewModel'

@Component({
  components: {
    NumberPicker
  }
})
export default class EditStatus extends Vue {

  @InjectReactive() authService!: AuthService
  viewModel = new EditStatusViewModel()

  beforeMount() {
    this.viewModel.init(this.authService)
  }

  mounted() {
    this.viewModel.load(this.$route.params.statusname)
  }

  async onSave(): Promise<void> {
    await this.viewModel.save()
    this.$router.back()
  }
}
