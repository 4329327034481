import Asset from '@/models/asset';
import IAssetRepository from '@/repositories/IAssetRepository';
import { $inject } from '@vanroeybe/vue-inversify-plugin';
import { injectable } from 'inversify';
import IUseCase from '../IUseCase';

@injectable()
export default class GetAsset implements IUseCase<string, Promise<Asset>> {

    @$inject()
    private readonly assetRepository!: IAssetRepository

    call(parameter: string): Promise<Asset> {
        return this.assetRepository.getAsset(parameter)
    }
}