


























import { Component, ProvideReactive, Vue } from 'vue-property-decorator'
import Login from './views/login/Login.vue'
import Assets from './views/assets/assetList/Assets.vue'
import Drawer from './views/main/Drawer.vue'
import AppBar from './views/main/AppBar.vue'
import AuthService from './services/AuthService'
import EditProfile from '@/views/profile/EditProfile.vue'

@Component({
  components: {
    Login,
    Assets,
    EditProfile,
    Drawer,
    AppBar
  }
})
export default class App extends Vue {

  @ProvideReactive() authService = new AuthService()

  miniVariant = true

  beforeDestroy() {
    this.authService.dispose()
  }
}
