import User from '@/models/user';
import IUserRepository from '@/repositories/IUserRepository';
import { $inject } from '@vanroeybe/vue-inversify-plugin';
import { injectable } from 'inversify';
import IUseCase from '../IUseCase';

@injectable()
export default class GetUserList implements IUseCase<(user: User[]) => void, () => void> {

    @$inject()
    private readonly userRepository!: IUserRepository

    call(parameter: (user: User[]) => void): () => void {
        return this.userRepository.getUserList(parameter)
    }
}