import firebase from 'firebase'

export const statusConverter = {
    toFirestore(data: Status) {
        return Object.assign({}, data)
    },
    fromFirestore(snap: firebase.firestore.QueryDocumentSnapshot) {
        return snap.data() as Status
    }
}

export default class Status {
    name?: string
    displayName?: string
    order?: number
}