import firebase from 'firebase'
import Status from './status'
import Tag from './tag'

export const assetConverter = {
    toFirestore(data: Asset) {
        return Object.assign({}, data)
    },
    fromFirestore(snap: firebase.firestore.QueryDocumentSnapshot) {
        return snap.data() as Asset
    }
}

export enum AssetType {
    Zip = 'zip',
    Png = 'png',
    Fbx = 'fbx'
}

export default class Asset {
    name?: string
    displayName?: string
    ticketLink?: string
    thumbnailUrl?: string
    assetType?: AssetType
    assetUrl?: string
    tags?: string[]
    status?: Status
}