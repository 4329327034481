import LoginUseCase, { LoginParams } from '@/useCases/auth/Login'

export default class LoginViewModel {

    email = ''
    password = ''
    loading = false

    get showError(): boolean {
        return this.error != null
    }

    set showError(value: boolean) {
        this.error = null
    }

    error: string | null = null

    async onLogin() {
        this.loading = true
        try {
            await new LoginUseCase().call(new LoginParams(this.email, this.password))
        } catch (e) {
            if (e instanceof Error) {
                this.error = e.message
            } else {
                this.error = 'General Error'
            }
        } finally {
            this.loading = false
        }
    }
}