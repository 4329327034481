








import User from '@/models/user';
import Vue from 'vue'
import Component from 'vue-class-component';
import UserListViewModel from './userListViewModel'

@Component
export default class UserList extends Vue {

  viewModel = new UserListViewModel()

  headers = [
    {
      text: 'Id',
      value: 'id'
    },
    {
      text: 'Username',
      value: 'username'
    }
  ]

  beforeDestroy() {
    this.viewModel.dispose()
  }

  onClickRow(user: User) {
    this.$router.push('/user/' + user.id + '/permissions')
  }
}
