import Status from '@/models/status';
import IStatusRepository from '@/repositories/IStatusRepository';
import { TYPES } from '@/utils/AmsContainer';
import { $inject } from '@vanroeybe/vue-inversify-plugin';
import { inject, injectable } from 'inversify';
import IUseCase from '../IUseCase';


@injectable()
export default class GetStatus implements IUseCase<string, Promise<Status | undefined>> {

    @$inject()
    private readonly statusRepository!: IStatusRepository

    call(parameter: string): Promise<Status | undefined> {
        return this.statusRepository.getStatus(parameter)
    }
}