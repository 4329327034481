import IAuthRepository from '@/repositories/IAuthRepository';
import { $inject } from '@vanroeybe/vue-inversify-plugin';
import { injectable } from 'inversify';
import IUseCase from '../IUseCase';

@injectable()
export default class Logout implements IUseCase<void, Promise<void>> {

    @$inject()
    private readonly authRepository!: IAuthRepository

    call(parameter: void): Promise<void> {
        return this.authRepository.logout()
    }

}