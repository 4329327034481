import Status, { statusConverter } from '@/models/status'
import firebase from 'firebase/app'
import { inject, injectable } from 'inversify'
import { provide } from 'inversify-binding-decorators'
import IStatusRepository from '../IStatusRepository'

@injectable()
export default class StatusRepository implements IStatusRepository {
    getStatusList(observer: (status: Status[]) => void): () => void {
        return firebase.firestore().collection('status').withConverter(statusConverter).onSnapshot(snapshot => {
            observer(snapshot.docs.map(doc => doc.data()))
        })
    }

    async getStatus(statusname: string): Promise<Status | undefined> {
        return (await firebase.firestore().doc('/status/' + statusname).withConverter(statusConverter).get()).data()
    }

    createOrUpdateStatus(status: Status): Promise<void> {
        return firebase.firestore().doc('status/' + status.name).withConverter(statusConverter).set(status)
    }

    deleteStatus(statusname: string): Promise<void> {
        return firebase.firestore().doc('/status/' + statusname).delete()
    }
}