





























































import Vue from 'vue'
import Component from 'vue-class-component';
import Asset from '@/models/asset';
import { InjectReactive, ProvideReactive } from 'vue-property-decorator';
import { PermissionName } from '@/models/permission';
import AssetList from './AssetList.vue'
import AssetsViewModel from './assetsViewModel'
import AuthService from '@/services/AuthService';

@Component({
  components: {
    AssetList
  }
})
export default class Assets extends Vue {

  @InjectReactive() authService!: AuthService
  @ProvideReactive() viewModel = new AssetsViewModel()


  get canCreateAssets(): boolean {
    return PermissionName.AssetsCreate.includeIn(this.authService.loggedInPermissions)
  }

  onItemClick(item: Asset) {
    this.$router.push('asset/' + item.name)
  }


  beforeDestroy() {
    this.viewModel.dispose()
  }
}
