import Permission from '@/models/permission';
import IUserRepository from '@/repositories/IUserRepository';
import { $inject } from '@vanroeybe/vue-inversify-plugin';
import { injectable } from 'inversify';
import IUseCase from '../IUseCase';

export class UpdatePermissionsParams {
    userId: string
    newPermissions: Permission[]
    oldPermissions: Permission[]


    constructor(userId: string, newPermissions: Permission[], oldPermissions: Permission[]) {
        this.userId = userId
        this.newPermissions = newPermissions
        this.oldPermissions = oldPermissions
    }
}

@injectable()
export default class UpdatePermissions implements IUseCase<UpdatePermissionsParams, Promise<void>> {

    @$inject()
    private readonly userRepository!: IUserRepository

    call(parameter: UpdatePermissionsParams): Promise<void> {
        return this.userRepository.updatePermissions(parameter.userId, parameter.newPermissions, parameter.oldPermissions)
    }

}