import IAuthRepository from '@/repositories/IAuthRepository';
import { $inject } from '@vanroeybe/vue-inversify-plugin';
import firebase from 'firebase/app'
import 'firebase/auth'
import { injectable } from 'inversify';
import IUseCase from '../IUseCase';

export class LoginParams {
    email: string
    password: string

    constructor(email: string, password: string) {
        this.email = email
        this.password = password
    }
}

@injectable()
export default class Login implements IUseCase<LoginParams, Promise<firebase.auth.UserCredential>> {

    @$inject()
    private readonly authRepository!: IAuthRepository

    call(parameter: LoginParams): Promise<firebase.auth.UserCredential> {
        return this.authRepository.login(parameter.email, parameter.password)
    }
}