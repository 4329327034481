import Permission from '@/models/permission'
import User from '@/models/user'
import GetAvailablePermissions from '@/useCases/permissions/GetAvailablePermissions'
import GetPermissions, { GetPermissionsParams } from '@/useCases/user/GetPermissions'
import GetUser from '@/useCases/user/GetUser'
import UpdatePermissions, { UpdatePermissionsParams } from '@/useCases/user/UpdatePermissions'
import { TYPES } from '@/utils/AmsContainer'
import { $inject } from '@vanroeybe/vue-inversify-plugin'

export default class EditUserPermissionsViewModel {
    @$inject(TYPES.GetUser)
    private readonly getUser!: GetUser

    @$inject(TYPES.GetAvailablePermission)
    private readonly getAvailablePermission!: GetAvailablePermissions

    @$inject(TYPES.GetPermissions)
    private readonly getPermissions!: GetPermissions

    @$inject(TYPES.UpdatePermissions)
    private readonly updatePermissions!: UpdatePermissions

    loading = false
    user!: User
    permissions: Permission[] = []
    userPermissions: Permission[] = []
    initialUserPermissions: Permission[] = []

    userPermissionSubscription?: () => void
    availablePermissionSubscription?: () => void

    async load(userId: string): Promise<void> {
        this.loading = true

        const user = await this.getUser.call(userId)
        this.user = Object.assign({}, user)

        this.availablePermissionSubscription = this.getAvailablePermission.call(permissions => {
            this.permissions = permissions
        })

        if (this.user && this.user.id) {
            this.userPermissionSubscription = this.getPermissions.call(new GetPermissionsParams(this.user.id, userPermissions => {
                this.userPermissions = userPermissions
                this.initialUserPermissions = userPermissions
            }))
        }

        this.loading = false
    }

    async save(): Promise<void> {
        await this.updatePermissions.call(new UpdatePermissionsParams(this.user.id!, this.userPermissions, this.initialUserPermissions))

    }

    dispose() {
        if (this.userPermissionSubscription) {
            this.userPermissionSubscription()
        }

        if (this.availablePermissionSubscription) {
            this.availablePermissionSubscription()
        }
    }
}