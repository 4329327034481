












































































































































import Vue from 'vue'
import Component from 'vue-class-component';
import FileDrag from '@/components/FileDrag.vue'
import { FileExtension } from '@/models/fileExtension'
import { PermissionName } from '@/models/permission';
import { InjectReactive, ProvideReactive } from 'vue-property-decorator';
import AuthService from '@/services/AuthService';
import EditAssetViewModel from './editAssetViewModel';

@Component({
  components: {
    FileDrag,
  }
})
export default class EditAsset extends Vue {

  @ProvideReactive() viewModel = new EditAssetViewModel()
  @InjectReactive() authService!: AuthService


  acceptableImageExtensions: FileExtension[] = [
    FileExtension.Png
  ]

  acceptableAssetExtensions: FileExtension[] = [
    FileExtension.Fbx,
    FileExtension.UnityPackage
  ]

  dragOver = false

  get canUpdateStatus(): boolean {
    return PermissionName.AssetsStatusUpdate.includeIn(this.authService.loggedInPermissions)
  }

  get canSave(): boolean {
    return this.viewModel.isNew ? PermissionName.AssetsCreate.includeIn(this.authService.loggedInPermissions) : PermissionName.AssetsUpdate.includeIn(this.authService.loggedInPermissions)
  }

  async beforeMount() {
    this.viewModel.load(this.$route.params.assetname)
  }

  beforeDestroy() {
    this.viewModel.dispose()
  }

  async onSave() {
    await this.viewModel.save()
    this.$router.back()
  }
}
