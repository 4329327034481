




































import { PermissionName } from '@/models/permission'
import AuthService from '@/services/AuthService'
import Vue from 'vue'
import { Component, InjectReactive } from 'vue-property-decorator'
import { version } from '../../../package.json'

@Component
export default class Drawer extends Vue {

  appVersion = version

  @InjectReactive() authService!: AuthService

  get showAssetsMenu(): boolean {
    return PermissionName.AssetsRead.includeIn(this.authService.loggedInPermissions)
  }

  get showUserMenu(): boolean {
    return PermissionName.UserRead.includeIn(this.authService.loggedInPermissions)
  }
}
