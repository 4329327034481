import Permission from '@/models/permission';
import IUserRepository from '@/repositories/IUserRepository';
import { $inject } from '@vanroeybe/vue-inversify-plugin';
import { injectable } from 'inversify';
import IUseCase from '../IUseCase';

export class GetPermissionsParams {
    userId!: string
    observer!: (permission: Permission[]) => void

    constructor(userId: string, observer: (permissions: Permission[]) => void) {
        this.userId = userId
        this.observer = observer
    }
}

@injectable()
export default class GetPermissions implements IUseCase<GetPermissionsParams, () => void> {

    @$inject()
    private readonly userRepository!: IUserRepository

    call(parameter: GetPermissionsParams): () => void {
        return this.userRepository.getPermissions(parameter.userId, parameter.observer)
    }
}