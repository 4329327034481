import Asset from '@/models/asset';
import Status from '@/models/status';
import DeleteAsset from '@/useCases/asset/DeleteAsset';
import GetAssets from '@/useCases/asset/GetAssets';
import GetStatusList from '@/useCases/status/GetStatusList';
import { TYPES } from '@/utils/AmsContainer';
import { $inject } from '@vanroeybe/vue-inversify-plugin';
import { injectable } from 'inversify';

@injectable()
export default class AssetsViewModel {

  @$inject(TYPES.GetAssets)
  private readonly getAssets!: GetAssets

  @$inject(TYPES.DeleteAsset)
  private readonly deleteAsset!: DeleteAsset


  assets: Asset[] = []
  status: Status[] = []
  assetToDelete: Asset | null = null
  filterQuery: string | null = null
  selectedStatusIndex = 0

  private assetsSubscription: () => void
  private statusSubscription: () => void


  constructor() {
    this.assetsSubscription = this.getAssets.call(assets => {
      this.assets = assets
    })

    this.statusSubscription = new GetStatusList().call(status => {
      this.status = status.sort((a, b) => (a.order ?? 0) - (b.order ?? 0))
    })
  }

  dispose() {
    if (this.assetsSubscription) {
      this.assetsSubscription()
    }

    if (this.statusSubscription) {
      this.statusSubscription()
    }
  }


  get assetsForStatus(): Asset[] {
    const filtered = this.assets.filter(a => a.status?.name == this.status[this.selectedStatusIndex].name && (this.filterQuery == null || a.displayName?.includes(this.filterQuery)))
    return filtered
  }

  onDeleteConfirmation(item: Asset) {
    this.assetToDelete = item
  }

  get showDeleteDialog(): boolean {
    return this.assetToDelete != null
  }

  set showDeleteDialog(value: boolean) {
    this.assetToDelete = null
  }

  async onDelete(): Promise<void> {
    if (this.assetToDelete?.name) {
      await this.deleteAsset.call(this.assetToDelete)
      this.assetToDelete = null
    }
  }
}
