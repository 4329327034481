import { PermissionName } from '@/models/permission'
import Status from '@/models/status'
import AuthService from '@/services/AuthService'
import CreateOrUpdateStatus from '@/useCases/status/CreateOrUpdateStatus'
import GetStatus from '@/useCases/status/GetStatus'
import { TYPES } from '@/utils/AmsContainer'
import { $inject } from '@vanroeybe/vue-inversify-plugin'

export default class EditStatusViewModel {
    @$inject(TYPES.GetStatus)
    private readonly getStatus!: GetStatus

    loading = false
    status: Status = new Status()

    isNew = false

    private authService!: AuthService

    init(authService: AuthService) {
        this.authService = authService
    }

    get canSave(): boolean {
        if (this.authService) {
            return this.isNew ? PermissionName.StatusCreate.includeIn(this.authService.loggedInPermissions) : PermissionName.StatusUpdate.includeIn(this.authService.loggedInPermissions)
        } else {
            return false
        }
    }

    async load(statusname: string): Promise<void> {
        this.loading = true

        this.isNew = statusname == 'new'
        if (this.isNew) {
            this.status.order = 0
        } else {
            const status = await this.getStatus.call(statusname)

            if (status) {
                this.status = status
            }
        }
        this.loading = false
    }

    onOrderChanged(value: number) {
        if (this.status) {
            this.status.order = value
            this.status = Object.assign({}, this.status)
        }
    }

    async save(): Promise<void> {
        if (this.status) {
            await (new CreateOrUpdateStatus().call(this.status))
        }
    }
}