import Permission, { PermissionName } from '@/models/permission';
import User from '@/models/user';
import IAuthRepository from '@/repositories/IAuthRepository';
import IPermissionRepository from '@/repositories/IPermissionRepository';
import IUserRepository from '@/repositories/IUserRepository';
import { $inject } from '@vanroeybe/vue-inversify-plugin';
import { injectable } from 'inversify';
import IUseCase from '../IUseCase';

@injectable()
export default class ObserverAuthChanged implements IUseCase<(user: User | null) => void, () => void> {

    @$inject()
    private readonly authRepository!: IAuthRepository

    @$inject()
    private readonly userRepository!: IUserRepository

    call(parameter: (user: User | null) => void): () => void {
        return this.authRepository.onAuthChanged(async (user, shouldCreateUser) => {
            if (shouldCreateUser && user) {
                await this.authRepository.createUser(user)
            }

            parameter(user)
        })
    }
}