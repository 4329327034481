


















































import Vue from 'vue'
import Component from 'vue-class-component';
import Status from '@/models/status';
import { InjectReactive } from 'vue-property-decorator';
import DeleteStatus from '@/useCases/status/DeleteStatus'
import AuthService from '@/services/AuthService';
import StatusListViewModel from './statusListViewModel'

@Component
export default class StatusList extends Vue {

  @InjectReactive() authService!: AuthService
  viewModel = new StatusListViewModel()

  headers = [
    {
      text: 'Name',
      value: 'name'
    },
    {
      text: 'Display Name',
      value: 'displayName'
    },
    {
      text: 'Order',
      value: 'order'
    },
    {
      text: 'Actions',
      value: 'actions'
    }
  ]

  beforeMount() {
    this.viewModel.init(this.authService)
  }

  beforeDestroy() {
    this.viewModel.dispose()
  }

  onClickRow(item: Status) {
    this.$router.push('/status/' + item.name)
  }

  onConfirmDelete(status: Status) {
    this.viewModel.statusToDelete = status
  }

  async onDelete(status: Status) {
    if (status.name) {
      await (new DeleteStatus()).call(status.name)
      this.viewModel.statusToDelete = null
    }
  }
}
