import Status from '@/models/status';
import IStatusRepository from '@/repositories/IStatusRepository';
import { inject, injectable } from 'inversify';
import IUseCase from '../IUseCase';
import { provide } from 'inversify-binding-decorators';
import { Inject } from 'vue-property-decorator';
import { $inject } from '@vanroeybe/vue-inversify-plugin';

@injectable()
export default class GetStatusList implements IUseCase<(status: Status[]) => void, () => void> {

    @$inject()
    private readonly statusRepository!: IStatusRepository

    call(parameter: (status: Status[]) => void): () => void {
        return this.statusRepository.getStatusList(parameter)
    }
}