import firebase from 'firebase'

export const userConverter = {
    toFirestore: (data: User) => Object.assign({}, data),
    fromFirestore: (snap: firebase.firestore.QueryDocumentSnapshot) => snap.data() as User
}

export default class User {
    id?: string
    username?: string
    email?: string

    public get isValid() {
        return this.id != undefined
    }
}