import { PermissionName } from '@/models/permission';
import Status from '@/models/status';
import AuthService from '@/services/AuthService';
import GetStatusList from '@/useCases/status/GetStatusList';
import { TYPES } from '@/utils/AmsContainer';
import { $inject } from '@vanroeybe/vue-inversify-plugin';
import { InjectReactive } from 'vue-property-decorator';

export default class StatusListViewModel {

    private authService!: AuthService

    status: Status[] = []

    init(authService: AuthService): void {
        this.authService = authService
        this.subscription = new GetStatusList().call(status => {
            this.status = status
        })
    }

    get showDeleteDialog(): boolean {
        return this.statusToDelete != null
    }

    set showDeleteDialog(value: boolean) {
        this.statusToDelete = null
    }

    get canCreateStatus(): boolean {
        return PermissionName.StatusCreate.includeIn(this.authService.loggedInPermissions)
    }

    statusToDelete: Status | null = null

    subscription?: () => void

    dispose() {
        if (this.subscription) {
            this.subscription()
        }
    }
}