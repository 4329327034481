












import Vue from 'vue'
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class NumberPicker extends Vue {
  @Prop() value!: number

  onPlus() {
    this.$emit('onValueChanged', this.value + 1)
  }

  onMinus() {
    this.$emit('onValueChanged', this.value - 1)
  }
}
