import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

import Assets from '../views/assets/assetList/Assets.vue'
import EditAsset from '../views/assets/editAsset/EditAsset.vue'
import StatusList from '../views/status/statusList/StatusList.vue'
import EditStatus from '../views/status/editStatus/EditStatus.vue'
import UserList from '../views/user/userList/UserList.vue'
import EditUserPermissions from '../views/user/editUserPermissions/EditUserPermissions.vue'
import ModelViewer from '@/components/ModelViewer.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    redirect: '/asset'
  },
  {
    path: '/viewer',
    name: 'ModelViewer',
    component: ModelViewer
  },
  {
    path: '/asset',
    name: 'Assets',
    component: Assets,
  },
  {
    path: '/asset/:assetname',
    name: 'EditAsset',
    component: EditAsset
  },
  {
    path: '/status',
    name: 'Status',
    component: StatusList
  },
  {
    path: '/status/:statusname',
    name: 'EditStatus',
    component: EditStatus
  },
  {
    path: '/user',
    name: 'User',
    component: UserList
  },
  {
    path: '/user/:userId/permissions',
    name: 'EditUserPermissions',
    component: EditUserPermissions
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
