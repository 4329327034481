import User from '@/models/user';
import GetUserList from '@/useCases/user/GetUserList';
import { TYPES } from '@/utils/AmsContainer';
import { $inject } from '@vanroeybe/vue-inversify-plugin';

export default class UserListViewModel {
    @$inject(TYPES.GetUserList)
    private readonly getUserList!: GetUserList

    user: User[] = []

    subscription?: () => void

    constructor() {
        this.subscription = this.getUserList.call(user => {
            this.user = user
        })
    }

    dispose(): void {
        if (this.subscription) {
            this.subscription()
        }
    }
}