








































import { FileExtension } from '@/models/fileExtension';
import Vue from 'vue'
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class FileDrag extends Vue {

  dragOver = false

  @Prop() width!: string
  @Prop() progress!: number
  @Prop() acceptableExtensions!: string[]
  @Prop() maxSize!: number

  get containerStyle(): string {
    return 'position: relative;width: ' + this.width
  }

  onDrop(event: DragEvent) {
    const fileName = event.dataTransfer?.files[0].name
    const type = fileName?.substring(fileName.lastIndexOf('.') + 1)

    if (this.acceptableExtensions.some(e => e.toString().toLowerCase() == type?.toLowerCase())) {
      if (this.maxSize == undefined || (event.dataTransfer?.files[0].size ?? 0) <= this.maxSize) {
        this.dragOver = false
        this.$emit('onFileChanged', event.dataTransfer?.files[0])
      } else {
        this.dragOver = false
        this.$emit('onError', 'File is to big')
      }

    } else {
      this.dragOver = false
      this.$emit('onError', 'Type ' + type + ' is not accepted')
    }
  }
}
