import firebase from 'firebase/app'
import 'firebase/firestore'
import user, { userConverter } from '@/models/user';
import IUserRepository from '../IUserRepository';
import { injectable } from 'inversify';
import User from '@/models/user';
import permission, { permissionConverter } from '@/models/permission';
import Permission from '@/models/permission';

@injectable()
export default class FirebaseUserRepository implements IUserRepository {
    updatePermissions(userId: string, newPermissions: permission[], oldPermissions: permission[]): Promise<void> {
        const toAdd = newPermissions.filter(np => oldPermissions.every(op => op.name != np.name))
        const toRemove = oldPermissions.filter(op => newPermissions.every(np => np.name != op.name))

        const batch = firebase.firestore().batch()

        toAdd.forEach(p => {
            const doc = firebase.firestore().doc('user/' + userId + '/permissions/' + p.name).withConverter(permissionConverter)
            batch.set(doc, p)
        })

        toRemove.forEach(p => {
            const doc = firebase.firestore().doc('user/' + userId + '/permissions/' + p.name).withConverter(permissionConverter)
            batch.delete(doc)
        })

        return batch.commit()
    }

    getPermissions(userId: string, observer: (permissions: permission[]) => void): () => void {
        return firebase.firestore().collection('user/' + userId + '/permissions').withConverter(permissionConverter).onSnapshot(snap => {
            observer(snap.docs.map(doc => doc.data()))
        })
    }

    updateProfile(user: user): Promise<void> {
        return firebase.firestore().doc('user/' + user.id + '/').withConverter(userConverter).update(user)
    }

    getUserList(observer: (user: user[]) => void): () => void {
        return firebase.firestore().collection('user').withConverter(userConverter).onSnapshot(snapshot => {
            const user = snapshot.docs.map(doc => doc.data())
            observer(user)
        })
    }

    async getUser(id: string): Promise<User | undefined> {
        const doc = await firebase.firestore().doc('user/' + id).withConverter(userConverter).get()
        return doc.data()
    }
}