import Asset from '@/models/asset';
import IAssetRepository from '@/repositories/IAssetRepository';
import { $inject } from '@vanroeybe/vue-inversify-plugin';
import { injectable } from 'inversify';
import IUseCase from '../IUseCase';

@injectable()
export default class GetAssets implements IUseCase<(assets: Asset[]) => void, () => void> {

    @$inject()
    private readonly assetRepository!: IAssetRepository

    call(parameter: (assets: Asset[]) => void): () => void {
        return this.assetRepository.getAssets(parameter)
    }

}