import AuthService from '@/services/AuthService'
import UpdateProfile, { UpdateProfileParams } from '@/useCases/profile/UpdateProfile'
import { TYPES } from '@/utils/AmsContainer'
import { $inject } from '@vanroeybe/vue-inversify-plugin'

export default class EditProfileViewModel {

    private authService!: AuthService

    newUsername = ''
    valid = false
    newPassword = ''
    newPasswordRepeat = ''

    @$inject(TYPES.UpdateProfile)
    private readonly updateProfile!: UpdateProfile




    init(authService: AuthService) {
        this.authService = authService
    }


    get passwordRepeatError() {
        return this.newPassword != '' && this.newPassword != this.newPasswordRepeat ? 'New password and Repeat New Password must be equal' : ''
    }

    async save() {
        if (!this.valid) {
            return
        }
        const newUser = this.authService.loggedInUser;

        if (newUser == null) {
            return
        }

        newUser.username = this.newUsername

        const params = new UpdateProfileParams(newUser, this.newPassword, this.newPasswordRepeat)

        await this.updateProfile.call(params)

        this.newPassword = ''
        this.newPasswordRepeat = ''
    }
}