import User, { userConverter } from '@/models/user';
import firebase from 'firebase/app'
import 'firebase/auth'
import { injectable } from 'inversify';

import IAuthRepository from '../IAuthRepository';

@injectable()
export default class FirebaseAuthRepository implements IAuthRepository {

    async changePassword(newPassword: string, newPasswordRepeat: string): Promise<void> {
        const currentUser = firebase.auth().currentUser

        if (currentUser) {
            return currentUser.updatePassword(newPassword)
        }
    }

    logout(): Promise<void> {
        return firebase.auth().signOut()
    }

    login(email: string, password: string): Promise<firebase.auth.UserCredential> {
        return new Promise<firebase.auth.UserCredential>((resolve, reject) => {
            firebase.auth().signInWithEmailAndPassword(email, password)
                .then(user => resolve(user))
                .catch(reason => {
                    reject(reason)
                })
        })
    }

    createUser(user: User): Promise<void> {
        return firebase.firestore().doc('/user/' + user.id).withConverter(userConverter).set(user)
    }

    onAuthChanged(handler: (user: User | null, shouldCreateUser: boolean) => void): () => void {
        return firebase.auth().onIdTokenChanged(async firebaseUser => {
            if (firebaseUser) {
                const doc = await firebase.firestore().doc('user/' + firebaseUser?.uid).withConverter(userConverter).get()
                const user = doc.data()
                if (user) {
                    user.email = firebaseUser?.email ?? ''
                    handler(user, false)
                } else {
                    const newUser = new User()
                    newUser.id = firebaseUser.uid
                    newUser.email = firebaseUser?.email ?? ''

                    handler(newUser, true)
                }
            } else {
                handler(null, false)
            }
        })
    }
}