



























import { Component, InjectReactive } from 'vue-property-decorator'
import Vue from 'vue'
import AuthService from '@/services/AuthService'
import EditProfileViewModel from './editProfileViewModel'

@Component
export default class EditProfile extends Vue {

  @InjectReactive() authService!: AuthService

  viewModel = new EditProfileViewModel()


  beforeMount() {
    this.viewModel.init(this.authService)
    this.viewModel.newUsername = this.authService.loggedInUser?.username ?? ''
  }


  async onSave() {
    await this.viewModel.save()
    this.$emit('saved')
  }

}
