import IStatusRepository from '@/repositories/IStatusRepository';
import IUseCase from '../IUseCase';

export default class DeleteStatus implements IUseCase<string, Promise<void>> {

    private readonly statusRepository!: IStatusRepository

    call(parameter: string): Promise<void> {
        return this.statusRepository.deleteStatus(parameter)
    }
}