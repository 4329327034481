








































import Asset from '@/models/asset'
import AssetItem from './AssetItem.vue'
import ModelViewer from '@/components/ModelViewer.vue'

import Vue from 'vue'
import { Component, InjectReactive, Prop } from 'vue-property-decorator'
import AssetsViewModel from './assetsViewModel'

@Component({
  components: {
    AssetItem,
    ModelViewer
  }
})
export default class AssetList extends Vue {
  @Prop() assets!: Asset[]
  @InjectReactive() viewModel!: AssetsViewModel

  modelLoadProgress = 0
  showWireframe = false

  get showModel() {
    return this.showModelForAsset != null
  }
  set showModel(value: boolean) {
    this.showModelForAsset = null
  }

  showModelForAsset: Asset | null = null

  on3D(asset: Asset) {
    this.showModelForAsset = asset
  }
}
