









































import Vue from 'vue'
import Component from 'vue-class-component';
import EditUserPermissionsViewModel from './editUserPermissionsViewModel'

@Component
export default class EditUserPermissions extends Vue {

  viewModel = new EditUserPermissionsViewModel()


  async beforeMount() {
    this.viewModel.load(this.$route.params.userId)
  }

  async onSave() {
    await this.viewModel.save()
    this.$router.back()
  }

  beforeDestroy() {
    this.viewModel.dispose()
  }
}
