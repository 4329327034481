import User from '@/models/user';
import IAuthRepository from '@/repositories/IAuthRepository';
import IUserRepository from '@/repositories/IUserRepository';
import { $inject } from '@vanroeybe/vue-inversify-plugin';
import { injectable } from 'inversify';
import IUseCase from '../IUseCase';

export class UpdateProfileParams {
    user: User
    newPassword: string
    newPasswordRepeat: string

    constructor(user: User, newPassword: string, newPasswordRepeat: string) {
        this.user = user
        this.newPassword = newPassword
        this.newPasswordRepeat = newPasswordRepeat
    }
}

@injectable()
export default class UpdateProfile implements IUseCase<UpdateProfileParams, Promise<void>> {

    @$inject()
    private readonly userRepository!: IUserRepository

    @$inject()
    private readonly authRepository!: IAuthRepository

    async call(parameter: UpdateProfileParams): Promise<void> {
        await this.userRepository.updateProfile(parameter.user)

        if (parameter.newPassword != '') {
            this.authRepository.changePassword(parameter.newPassword, parameter.newPasswordRepeat)
        }
    }
}