import Tag from '@/models/tag';
import ITagRepository from '@/repositories/ITagRepository';
import { $inject } from '@vanroeybe/vue-inversify-plugin';
import { injectable } from 'inversify';
import IUseCase from '../IUseCase';

@injectable()
export default class GetTags implements IUseCase<(tags: Tag[]) => void, () => void> {

    @$inject()
    private readonly tagRepository!: ITagRepository

    call(parameter: (tags: Tag[]) => void): () => void {
        return this.tagRepository.getAllTags(parameter)
    }
}