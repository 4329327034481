import Asset from '@/models/asset'
import Status from '@/models/status'
import GetAsset from '@/useCases/asset/GetAsset'
import UpdateAsset, { UpdateAssetParams } from '@/useCases/asset/UpdateAsset'
import GetStatusList from '@/useCases/status/GetStatusList'
import GetTags from '@/useCases/tags/GetTags'
import { TYPES } from '@/utils/AmsContainer'
import { $inject } from '@vanroeybe/vue-inversify-plugin'

export default class EditAssetViewModel {
  @$inject(TYPES.UpdateAsset)
  private readonly updateAsset!: UpdateAsset

  @$inject(TYPES.GetAsset)
  private readonly getAsset!: GetAsset

  @$inject(TYPES.GetTags)
  private readonly getTags!: GetTags

  asset: Asset = new Asset()
  tags: string[] = []
  status: Status[] = []

  loading = false
  isNew = false
  errorMessage = ''
  showError = false
  saving = false
  thumbnailUploadProgress = 0
  assetUploadProgress = 0

  thumbnailFile: File | null = null
  assetFile: File | null = null

  tagSubscription?: () => void
  statusSubscription?: () => void


  async load(assetName: string) {
    this.loading = true

    this.isNew = assetName == 'new'

    try {
      if (this.isNew) {
        this.asset = new Asset()
      } else {
        this.asset = await this.getAsset.call(assetName)
      }

      this.tagSubscription = this.getTags.call(tags => {
        this.tags = tags.map(t => t.name!)
      })

      this.statusSubscription = new GetStatusList().call(status => {
        this.status = status
        if (!this.asset.status) {
          this.asset.status = this.status.sort((a, b) => (a.order ?? 0) - (b.order ?? 0))[0]
        }
      })
    } catch (error) {

    } finally {
      this.loading = false
    }
  }

  async save() {
    this.saving = true

    if (this.asset) {
      const params = new UpdateAssetParams()
      params.asset = this.asset
      params.assetFile = this.assetFile!
      params.thumbnailFile = this.thumbnailFile!
      params.onThumbnailProgress = progress => {
        this.thumbnailUploadProgress = progress
      }
      params.onProgress = progress => {
        this.assetUploadProgress = progress
      }

      await this.updateAsset.call(params)

      this.thumbnailUploadProgress = 0
      this.assetUploadProgress = 0
    }

    this.saving = false
  }

  removeTag(tag: string) {
    if (this.asset) {
      this.asset?.tags?.splice(this.asset.tags.indexOf(tag), 1)
    }
  }

  get thumbnailUrl() {
    if (this.thumbnailFile != null) {
      return URL.createObjectURL(this.thumbnailFile)
    } else {
      return this.asset?.thumbnailUrl
    }
  }

  addThumbnailFile(file: File) {
    this.thumbnailFile = file
  }

  addAssetFile(file: File) {
    this.assetFile = file
  }

  onError(message: string) {
    this.errorMessage = message
    this.showError = true
  }

  dispose() {
    if (this.tagSubscription) {
      this.tagSubscription()
    }

    if (this.statusSubscription) {
      this.statusSubscription()
    }
  }
}
