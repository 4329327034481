import Asset, { AssetType } from '@/models/asset';
import IAssetRepository from '@/repositories/IAssetRepository';
import ITagRepository from '@/repositories/ITagRepository';
import { $inject } from '@vanroeybe/vue-inversify-plugin';
import { injectable } from 'inversify';
import IUseCase from '../IUseCase';

export class UpdateAssetParams {
    asset!: Asset
    assetFile!: File
    thumbnailFile!: File
    onThumbnailProgress!: (progress: number) => void
    onProgress!: (progress: number) => void
}

@injectable()
export default class UpdateAsset implements IUseCase<UpdateAssetParams, Promise<void>> {

    @$inject()
    private readonly tagRepository!: ITagRepository

    @$inject()
    private readonly assetRepository!: IAssetRepository

    async call(parameter: UpdateAssetParams): Promise<void> {

        if (parameter.thumbnailFile != null) {
            const thumbnailUrl = await this.assetRepository.uploadThumbnail(parameter.asset, parameter.thumbnailFile, parameter.onThumbnailProgress)

            parameter.asset.thumbnailUrl = thumbnailUrl
        }

        if (parameter.assetFile != null) {
            const url = await this.assetRepository.uploadAsset(parameter.asset, parameter.assetFile, parameter.onProgress)


            const ext = parameter.assetFile.name.substring(parameter.assetFile.name.lastIndexOf('.') + 1).toLowerCase()
            parameter.asset.assetType = ext as AssetType
            parameter.asset.assetUrl = url
        }

        this.tagRepository.createTagsIfNeeded(parameter.asset.tags)
        this.assetRepository.updateAsset(parameter.asset)
    }

}