import firebase from 'firebase'

export class PermissionName {
    public static AssetsRead = new PermissionName('assets.read')
    public static AssetsStatusUpdate = new PermissionName('assets.status.update')
    public static AssetsCreate = new PermissionName('assets.create')
    public static AssetsUpdate = new PermissionName('assets.update')
    public static UserRead = new PermissionName('user.read')
    public static StatusCreate = new PermissionName('status.create')
    public static StatusUpdate = new PermissionName('status.update')

    name: string

    constructor(name: string) {
        this.name = name
    }

    includeIn(permissions: Permission[]): boolean {
        return permissions.some(p => p.name == this.name)
    }
}

export const permissionConverter = {
    toFirestore: (data: Permission) => Object.assign({}, data),
    fromFirestore: (snap: firebase.firestore.QueryDocumentSnapshot) => snap.data() as Permission
}

export default class Permission {
    name?: string


}