




import { Component, Prop, Watch } from 'vue-property-decorator';
import Vue from 'vue'
import { BoxGeometry, Camera, Color, DirectionalLight, Fog, GridHelper, Group, HemisphereLight, Material, Mesh, MeshBasicMaterial, MeshPhongMaterial, PerspectiveCamera, PlaneGeometry, Scene, WebGLRenderer, WireframeGeometry } from 'three';
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
import Asset from '@/models/asset';

@Component
export default class ModelViewer extends Vue {

  @Prop() asset!: Asset
  @Prop() showWireframe!: boolean

  renderer!: WebGLRenderer
  scene!: Scene
  camera!: PerspectiveCamera
  cube!: Mesh
  container!: HTMLElement

  private fbx!: Group

  @Watch('showWireframe')
  onShowWireframeChanged(value: boolean, oldValue: boolean) {
    this.fbx.traverse(child => {

      const mesh = child as Mesh
      if (mesh) {
        const mat = (mesh.material as MeshPhongMaterial)
        if (mat) {
          mat.wireframe = value
        }
      }
    })
  }

  mounted() {
    this.container = document.getElementById('content')!

    this.scene = new Scene()
    this.scene.background = new Color(0xa0a0a0)
    this.scene.fog = new Fog(0xa0a0a0, 200, 1000)

    const width = this.container.offsetWidth
    const height = this.container.offsetHeight

    this.camera = new PerspectiveCamera(45, width / height, 0.1, 2000)

    this.renderer = new WebGLRenderer({ antialias: true })
    this.renderer.setSize(width, height)
    this.renderer.shadowMap.enabled = true
    this.renderer.setPixelRatio(window.devicePixelRatio)
    this.container?.appendChild(this.renderer.domElement)

    this.camera.position.set(100, 200, 300)

    const hemiLight = new HemisphereLight(0xffffff, 0x444444)
    hemiLight.position.set(0, 200, 0)
    this.scene.add(hemiLight)

    const dirLight = new DirectionalLight(0xffffff)
    dirLight.position.set(0, 200, 100)
    dirLight.castShadow = true
    dirLight.shadow.camera.top = 180
    dirLight.shadow.camera.bottom = -100
    dirLight.shadow.camera.left = -120
    dirLight.shadow.camera.right = 120
    this.scene.add(dirLight)

    const mesh = new Mesh(new PlaneGeometry(2000, 2000), new MeshPhongMaterial({ color: 0x999999, depthWrite: false }))
    mesh.rotation.x = - Math.PI / 2
    mesh.receiveShadow = true
    this.scene.add(mesh)

    const grid = new GridHelper(2000, 20, 0x000000, 0x000000)
    const mat = grid.material as Material
    mat.opacity = 0.2
    mat.transparent = true

    this.scene.add(grid)


    const loader = new FBXLoader()

    this.$emit('onProgress', 0)

    loader.load(this.asset.assetUrl!, fbx => {
      this.$emit('onProgress', 1)
      fbx.traverse(child => {
        if (child.isObject3D) {
          child.castShadow = true
          child.receiveShadow = true
        }

        if (this.showWireframe) {
          const mesh = child as Mesh
          if (mesh) {
            const mat = (mesh.material as MeshPhongMaterial)
            if (mat) {
              mat.wireframe = true
            }
          }
        }
      })
      this.fbx = fbx
      this.scene.add(this.fbx)
    }, (progress) => {
      this.$emit('onProgress', progress.loaded / progress.total)
    })


    const controls = new OrbitControls(this.camera, this.renderer.domElement)
    controls.target.set(0, 100, 0)
    controls.update()


    this.animate()
  }

  onWindowResize() {
    const width = this.container.clientWidth
    const height = this.container.clientHeight

    this.camera.aspect = width / height
    this.camera.updateProjectionMatrix()
    this.renderer.setSize(width, height)
  }

  animate() {
    this.onWindowResize()
    requestAnimationFrame(this.animate)
    this.renderer.render(this.scene, this.camera)
  }
}
