






















import User from '@/models/user'
import AuthService from '@/services/AuthService'
import Logout from '@/useCases/auth/Logout'
import { TYPES } from '@/utils/AmsContainer'
import { $inject } from '@vanroeybe/vue-inversify-plugin'
import Vue from 'vue'
import { Component, InjectReactive } from 'vue-property-decorator'

@Component
export default class AppBar extends Vue {

  @InjectReactive() authService!: AuthService

  onLogout() {
    this.$emit('onLogout')
    this.authService.logout()
  }
}
